@import '../../scss/base.scss';

.success {
	width: 100%;
	margin-bottom: $gap-double;

	&__pagination {
		display: flex;
		justify-content: center;
		margin-bottom: $gap-middlePlus;
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, 260px);
		justify-content: center;
		grid-column-gap: $gap-middlePlus;
		grid-row-gap: $gap-middlePlus;

		&__alternative {
			grid-column: 1 / 4;
		}
	}
}