@import '../../common/scss/base.scss';

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  top: 36px;
  right: 24px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $dark-grey;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $montrose-blue;
}

.bm-cross {
  /* Color/shape of close button cross */
  background: $white;
  width: 5px !important;
  height: 24px !important;
  &-button {
		width: 45px !important;
		height: 45px !important;
		top: 18px !important;
		right: 20px !important;
    > span {
      top: 12px !important;
			right: 24px !important;
    }
  }
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  // position: fixed;
  // height: 100%;
  width: 100% !important;
}

/* General sidebar styles */
.bm-menu {
  background: $montrose-blue;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
	outline: none;
	display: flex !important;
	flex-direction: column;
	> a {
		display: inline-block;
		color: $white;
		text-decoration: none;
		margin: $gap-top $none;
	}

	.admin {
		margin: $gap-top $none;
		&__content, &__admin-only {
			display: flex;
			flex-direction: column;
			> a {
				display: inline-block;
				color: $white;
				text-decoration: none;
				margin: $unit $none $unit $gap-singlePlus;
			}
		}
	}
	.logout {
		padding: $none;
		color: $white;
		margin: $gap-top $none;
	}
}