@import '../../../common/scss/base.scss';

.region-edit {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	max-width: 650px;
	margin: $none auto;

	> div {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__actions {
		&__number-picker {
			margin-top: $gap-side;
		}
	}

	&__name {
		margin-bottom: $gap-top;
	}

	&__submit {
		display: flex;
		flex-direction: column;
		padding: $gap-side $none;
		&__info-text {
			padding-bottom: $gap-side;
			font-size: $font-s;
		}
	}
}
