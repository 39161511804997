@import '../../scss/base.scss';

.leaflet-container {
	min-height: 200px;
	height: 400px;

	@include mq($from: 600px) {
		height: 600px;
	}
}
.map {
	&__container {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	&__loading-screen {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		z-index: 1500;
		background-color: rgba(250, 250, 250, 0.6);
		top: 0;
    bottom: 0;
    right: 0;
		left: 0;
	}

	&__info-box {
		position: absolute;
    z-index: 1000;
    bottom: 12px;
    left: 12px;
    padding: 12px;
    background-color: $white;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
    border-radius: $border-radius;
	}

	&__reset-btn {
		position: absolute;
		z-index: 1000;
		top: 10px;
		right: 10px;
	}
}

#seed-button {
	text-align: center;
	font-weight: bold;
	cursor: pointer;
}