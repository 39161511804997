@import '../scss/base.scss';

.block {
	z-index: 102;
	right: 100px;
	bottom: 40px;

	@include mq($from: 800px) {
		position: absolute;
	}
}

.carousel-cta {
	opacity: 0.9;
	border-radius: 10px;
	text-align: center;
	border: 0.5px solid white;
	background-color: white;
	text-align: right;
	padding: 0 16px;

	&__lead {
		position: relative;
		font-weight: 700;
		font-size: 16px;
		line-height: 0.1em;
		color: Black;

		@include mq($from: 800px) {
			font-size: 38px;
		}
	}
}

.carousel-button {
	margin: 10px 0 0 0;
	text-align: right;
	opacity: 1;
}
