@import '../../../common/scss/base.scss';

.advanced-filters {
	width: 420px;
	background-color: $faded-light-blue;
	border-radius: $border-radius;
	overflow: hidden;
	transition: all 0.2s ease-in-out;
	height: 0;
	width: 100%;

	&--isOpen {
		transition: all 0.2s ease-in-out;
		height: 180px;
	}

	&__dates {
		display: flex;
		flex-direction: column;
		max-width: 200px;
		margin: $gap-top $none;
		margin: $gap-top $gap-single;
	}

	&__tags {
		margin: $none $gap-single;
	}
}