@import '../../common/scss/base.scss';

.navigation-link {
	color: $dark-grey;
	text-decoration: none;
	padding: $unit $gap-middle;
	margin: $none calc($unit / 2);

		&:hover {
			background-color: $montrose-blue;
			color: $white;
			border-radius: $border-radius;
		}
	&--active {
		background-color: $montrose-blue;
		color: $white;
		border-radius: $border-radius;
	}
}