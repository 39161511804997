@import '../scss/base.scss';

.publication {
	@include page-contain;

	@include mq($until: 620px) {
		> figure {
			margin: $none;
		}
	}

	> figure {
		text-align: center;
		margin-bottom: 0;
	}

	img {
		max-width: 100%;
	}

	&__image {
		width: 750px;
		height: 400px;
		object-fit: cover;
	}

	h6 {
		margin: 0;
		font-size: 14px;
		font-weight: 400;
		font-style: italic;
		text-align: center;
		max-width: 500px;
		margin: auto;
	}

	&--loading {
		display: flex;
		justify-content: center;
		margin-top: 20%;
	}
}