@import '../../../common/scss/base.scss';

.video-admin {
	@include page-contain;

	> ol > li {
		& > a {
			color: $montrose-blue;
		}
	}
}
