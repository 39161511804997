@import '../../../common/scss/base.scss';

.publication-creator {
	display: flex;
	flex-direction: column;

	@include page-contain;

	&__btn-container {
		display: flex;
		justify-content: flex-end;

		@include mq($until: 370px) {
			flex-direction: column;
			align-items: center;
		}

		&__btn {
			margin: $unit $none;
			width: 100%;
			> button {
				width: 100%;
			}
			@include mq($from: 371px) {
				align-self: flex-end;
				margin: $none;
				width: unset;

				&--delete {
					margin-right: $unit;
				}
			}
		}
	}
}

.publish-description {
	&__title {
		display: block;
		font-weight: bold;
		color: $dark-grey;
		font-size: $font-s;
		margin-bottom: $unit;
	}

	&__input {
		height: 40px;
		margin: $unit $none;
		padding: $none $gap-side;
		border: 1px solid $light-grey;
		border-radius: $border-radius;
		font-size: $font-m;
	}
}
