@import '../scss/base.scss';

.homepage-twitter-timeline {
	margin-left: $gap-side;

	&__container {
		border: 0.5px solid $light-grey;
		border-radius: $border-radius;
		padding: $gap-top;
		width: 360px;
	}

	.timeline-Tweet-media {
		margin-left: 0;
	}
}