@import '../scss/base.scss';

.unavailable {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: $gap-top auto $gap-double;

	&__text {
		display: flex;
		flex-direction: column;
		margin-left: $gap-single;

		&__primary {
			font-size: $font-lm;

			@include text-bold;
		}
	}
}