@import '../../common/scss/base.scss';

.event-detail {
	text-align: center;

	&__time {
		margin: $gap-single $none;
	}

	&__description {
		display: flex;
		flex-direction: column;
		background-color: $faded-light-grey;
		margin: $gap-single $none;
		padding: $gap-middlePlus;
	}

	&__documents {
		display: flex;
		flex-direction: column;
		margin: $gap-single $none;

		&__title {
			font-weight: 600;
		}

		&__attachments {
			display: flex;
			justify-content: center;
			margin: $gap-top $none;
		}

		&__doc {
			cursor: pointer;
			text-decoration: none;
			color: $dark-grey;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: $none $gap-top;
			text-align: center;

			> span {
				display: inline-block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 125px;
			}
		}
	}
}
