@import '../../common/scss/base.scss';

#setup-account {
	@include page-contain;

	.setup {
		&__container {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&__email-input {
			width: 500px;
			max-width: 100%;
			margin-bottom: $gap-side;
		}

		&__error-message {
			color: red;
		}
	}
}
