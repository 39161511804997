//company palette
// $montrose-blue: #1b5bba;
$montrose-blue: #0072c7;
$montrose-green: #1fb53a;
$montrose-dark-blue: #164289;
$montrose-grey: #e8f1f2;
$montrose-black: #0a2239;
$montrose-light-blue: #53a2be;
$montrose-dark-blue: #176087;
$montrose-dark-green: #132e32;

//fades
$faded-montrose-blue: rgba(0, 114, 199, 0.5);
$semi-faded-dark-grey: rgba(60, 60, 60, 0.8);
$faded-dark-grey: rgba(60, 60, 60, 0.2);
$faded-light-blue: rgba(83, 162, 190, 0.5);
$faded-red: rgba(163, 22, 33, 0.5);
$faded-light-grey: rgba(211, 211, 211, 0.2);

//fonts
$dark-grey: #3c3c3c;
$link-color: #0074d9;

//backgrounds
$white: #fff;
$light-grey: #d3d3d3;
$yellow: #f8c061;

//from palette
$montrose-red: #a31621;

:export {
	montroseBlue: $montrose-blue;
	fadedMontroseBlue: $faded-montrose-blue;
	montroseGreen: $montrose-green;
	linkColor: $link-color;
}
