@import '../../common/scss/base.scss';

.map-key {
	border-radius: $border-radius;
	margin: $gap-top $none;
	padding: $gap-top;
	background-color: $faded-light-blue;

	&__title {
		border-bottom: 1px solid $faded-dark-grey;
		padding-bottom: $gap-side;
		display: flex;
		justify-content: space-between;

		@include text-bold;

		&__chevron {
			margin-right: 20px;
		}
	}

	&__legend {
		overflow: hidden;
		transition: all 0.2s ease-in-out;
		height: 0;

		display: flex;
		flex-direction: column;
		@include mq($from: tablet) {
			flex-direction: row;
		}
	
		&__regions {
			display: flex;
			flex-direction: column;
		}
	
		&__icons {
			display: flex;
			flex-wrap: wrap;
		}

		&--open {

			transition: all 0.2s ease-in-out;
			height: 980px;

			@include mq($from: 380px, $until: 671px) {
				height: 840px;
			}

			@include mq($from: 672px, $until: 949px) {
				height: 590px;
			}

			@include mq($from: 950px) {
				height: 400px;
			}
		}
	}
}
