@import '../../common/scss/base.scss';

@mixin nav-hover {
	background-color: $montrose-blue;
	color: white;
	border-radius: $border-radius;
}

.navigation {
	background-color: $white;
	display: flex;

	@include mq($from: 1090px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: $gap-side $gap-single;
	}

	&__logos {
		height: 75px;
		margin: $gap-top $none;
		&__logo {
			height: 100%;
			margin: $none $gap-side;
		}
	}

	&__burger {
		@include mq($from: 1090px) {
			display: none;
		}
	}

	&__link-container {
		@include mq($until: 1090px) {
			display: none;
		}

		.media {
			display: inline-block;
			padding: $none $gap-middlePlus;
			position: relative;

			&__content {
				display: none;
				flex-direction: column;
				position: absolute;
				margin-top: $none;
				left: 0;
				border-radius: $border-radius;
				background-color: $white;
				min-width: 160px;
				box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
				z-index: 1;
				overflow: hidden;
				> a {
					padding: $gap-side $gap-middlePlus;
					border-radius: unset;
					margin: $none;
				}
			}

			&:hover {
				cursor: context-menu;

				@include nav-hover;

				.media__content {
					display: flex;
				}
			}
		}

		.admin {
			display: inline-block;
			padding: $none $gap-middlePlus;
			position: relative;

			&__content {
				display: none;
				flex-direction: column;
				position: absolute;
				margin-top: $none;
				left: 0;
				border-radius: $border-radius;
				background-color: $white;
				min-width: 160px;
				box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
				z-index: 1;
				overflow: hidden;
				> a {
					padding: $gap-side $gap-middlePlus;
					border-radius: unset;
					margin: $none;
				}
			}

			&:hover {
				cursor: context-menu;

				@include nav-hover;

				.admin__content {
					display: flex;
				}
			}

			&__admin-only {
				display: flex;
				flex-direction: column;
				> a {
					padding: $gap-side $gap-middlePlus;
					border-radius: unset;
					margin: $none;
				}
			}
		}
		.logout {
			padding: $unit $gap-middlePlus;
			cursor: pointer;

			&:hover {
				@include nav-hover;
			}
		}
	}
}
