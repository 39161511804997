@import '../../common/scss/base.scss';

.publications {
	&__container {
		@include page-contain;
	}

	&__table-of-contents {
		border: 1px solid $light-grey;
		border-radius: $border-radius;
		position: absolute;
		left: $gap;

		@include mq($until: 1248px) {
			display: none;
		}

		&__fixed {
			position: fixed;
			border: 1px solid $light-grey;
			border-radius: $border-radius;
			padding: $gap $gap-middlePlus;
			width: 120px;
		}

		&__title {
			font-size: $font-l;
			@include text-bold;
		}

		&__list {
			list-style-type: none;
			padding: $none;

			> li {
				cursor: pointer;
				padding-left: $unit;
				margin: $gap-side $none;
			}
		}
	}

	&__mobile-toc {
		position: fixed;
		left: 0;
		bottom: 200px;
		border: 1px solid $light-grey;
		background-color: $white;
		padding: $gap-middlePlus $gap-middlePlus $gap-middlePlus $gap-side;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		border-left: none;
		display: flex;
		z-index: 10;
		outline: none;

		@include mq($from: 1249px) {
			display: none;
		}
	}

	&__lists {
		display: flex;
		flex-direction: column;
	}

	&__fact-sheet {
		margin: 24px auto;

		&__details {
			> p {
				margin: $none;
				text-align: center;
			}
		}

		> a {
			max-width: 350px;
			text-decoration: none;
			color: $dark-grey;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid $light-grey;
			padding: 24px;
			border-radius: $border-radius;
			transition: 0.3s;
			cursor: pointer;

			&:hover {
				border-color: $montrose-green;
				background-color: $montrose-green;
				color: $white;
			}
		}
	}
}
