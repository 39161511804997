@import '../../scss/base.scss';

.FilesDragAndDrop {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 2px $light-grey dashed;
	border-radius: $border-radius;

	&__title {
		font-size: $font-s;
		color: $dark-grey;
		font-weight: bold;
		display: block;
		text-transform: uppercase;
	}

	&__remove {
		font-size: $font-s;
		color: $faded-red;
		font-weight: bold;
		display: block;
		text-align: center;
		text-transform: uppercase;
		cursor: pointer;
	}

	&__blob {
		width: 300px;
		display: block;
		margin: 0 auto;
	}

	&__placeholder {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 9999;
		background-color: $montrose-blue;
		border-radius: $border-radius;
		opacity: 0.3;
	}

	&__area {
		height: 150px;
		padding: $gap-side;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-flow: column nowrap;
		text-align: center;

		&__browse {
			color: $montrose-blue;
			cursor: pointer;
		}

		&__formats {
			font-size: $font-s;
			color: $light-grey;
			font-weight: bold;
		}
	}
}
