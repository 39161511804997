
.marker {

	&__icon {

	}

	&--selected {
		border: 1px solid white;
		padding: 10px;
		width: 10px;
		height: 10px;
	}
}