@import '../../scss/base.scss'; 

.success-grid-item {
	width: 250px;
  position: relative;
  display: inline-block;
  max-width: 100%;
  -webkit-column-break-inside: avoid;
  .inner {
    padding: $gap-middlePlus;
  }
}

.content-box {
  border: 0px solid $montrose-grey;
  background-color: $white;
	box-shadow: 0px -1px 2px $faded-dark-grey, 1px 1px 2px $faded-dark-grey;
	line-height: 1.5em;
	font-size: $font-s;
  
  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 4rem;
    left: 0px;
    top: 0px;
	}
	
	&__thumb {
		display: block;
		width: 100%;
		height: 150px;
		object-fit: cover;
		margin: $unit 0;

		&--hidden {
			display: none;
		}
	}

	.placeholder {
		display: flex;
		justify-content: center;
	}

	&__header {
		margin: $gap-side $none $none;
		font-weight: 600;
	}

	&__date {
		display: block;
		color: $semi-faded-dark-grey;
		font-weight: 400;
		margin: 0;
	}

	&__blurb {
		display: block;
		display: -webkit-box;
		margin: 0rem;
		max-width: 100%;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		color: $montrose-dark-blue;
			
		@include text-bold;
	}
}

.readmore-link { 
  display: block;
  margin: $gap-side $none;
	color: $link-color;
  text-decoration: none;
  font-size: $font-s;
	opacity: 0.75;
	transition: ease-in-out 0.6s;
  &:hover {
    transition: ease-in-out 0.6s;
    opacity: 1;
  }
}