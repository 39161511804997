@import '../scss/base.scss';

.delete-modal {
	&__title {
		display: block;
		font-size: $font-l;
		margin-bottom: $gap-top;

		@include text-bold;
	}

	&__message {
		margin-bottom: $gap-top;
		text-align: center;

		&__email {
			@include text-bold;
		}
	}

	&__buttons {
		display: flex;
		flex-direction: column;
		align-items: center;

		> div {
			margin-bottom: 10px;
		}

		@include mq($from: mobile) {
			justify-content: space-around;
			flex-direction: row;
			> button {
				margin: $none;
			}
		}
	}
}