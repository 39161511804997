@import '../../scss/base.scss';

.image-upload {
	display: flex;
	flex-direction: column;
	font-size: $font-s;

	&__select-container {
		margin-top: $gap-middlePlus;
		padding: 18.5px 14px;
		font-size: $font-m;
		border: 1px solid rgba(118, 118, 118, 0.3);
		border-radius: $border-radius;
		cursor: pointer;
		height: 20px;
		line-height: 20px;

		&:hover {
			border-color: $dark-grey;
		}

		&--disabled {
			cursor: not-allowed;
			color: rgba(118, 118, 118, 0.3);
		}
	}
}
