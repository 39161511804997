@import '../scss/base.scss';

.modal {
	position: relative;
	padding: $gap-middle $gap-middlePlus;
	margin: auto;
	display: flex;
	flex-direction: column;
	border-radius: $border-radius;
	background-color: $white;
	width: 600px;
	max-width: 100%;
	-webkit-box-shadow: 10px 10px 34px 0px rgba(60, 60, 60, 0.5);
	-moz-box-shadow: 10px 10px 34px 0px rgba(60, 60, 60, 0.5);
	box-shadow: 10px 10px 34px 0px rgba(60, 60, 60, 0.5);
	outline: 0;

	&__title-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 60px;
		border-bottom: 2px solid $dark-grey;

		&__title {
			font-size: $font-l;
			color: $montrose-blue;

			@include text-bold;
		}

		&__close-btn {
			margin-left: 20px;
			cursor: pointer;
		}
	}

	&__child {
		padding: $gap-middlePlus $none;
	}
}
