@import '../variables/breakpoints.scss';

@mixin page-contain {
	width: 100%;

	@include mq($from: 850px) {
		max-width: 900px;
		margin: auto;
	}
}

@mixin text-bold {
	font-weight: 600;
}