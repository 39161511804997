@import '../../../common/scss/base.scss';

.map-admin {
	&__editor {
		border: 1px solid $dark-grey;
		border-radius: $border-radius;
		padding: $gap-single $none;
		margin-bottom: $gap-side;
		text-align: center;
		position: relative;
		min-height: 250px;
		display: flex;
		align-items: center;
		justify-content: center;

		&__back-icon {
			position: absolute;
			left: 50px;
			cursor: pointer;
			margin: auto $none;
			top: $gap-top;
			left: $gap-top;

			@include mq($from: desktop) {
				left: 100px;
				bottom: 0;
			}

			@include mq($from: 1410px) {
				left: 150px
			}
		}
	}
}