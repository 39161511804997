@import '../../common/scss/base.scss';

.what-we-do {

	&__subtitle {
		text-align: center;
	}

	&__section {
		margin: $none $none 2em;

		p {
			margin: 1em $none;
		}
	}

	&__title {
		margin: 1em $none;
	}

	&__image {
		max-width: 600px;
		margin: auto;
		img {
			max-width: 100%;
			max-height: 400px;
			display: block;
			margin: auto;
		}
		&__caption {
			font-size: $font-s;
			font-style: italic;
			display: block;
			text-align: center;
		}
	}
}