@import '../scss/base.scss';

.shared-input {
	display: flex;
	flex-direction: column;
	&__label {
		font-weight: bold;
		color: $dark-grey;
		font-size: $font-s;
	}

	&__helper-text {
		font-size: $font-s;

		&--error {
			color: red;
		}
	}

	&__input {
		margin: $none;
		padding: $gap-side;
		border: 1px solid $light-grey;
		border-radius: $border-radius;
		font-size: $font-m;
		box-sizing: border-box;
		font-family: $font-family;
		max-width: 100%;

		&--with-height {
			height: 40px;
		}

		&--full-width {
			width: 100%;
		}

		&--error {
			border-color: red;
			outline-color: red;
		}
	}
}
