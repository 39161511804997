$font-family: 'helvetica', sans-serif;

$global-font-base: 16px;
$global-line-height-base: 24px;

$font-base: $global-font-base;

// this will be properly calculated once we have a definitive rhythm
$font-xxs: 10px;
$font-xs: 12px;
$font-s: 14px;
$font-sm: 15px;
$font-m: $font-base;
$font-ml: 20px;
$font-l: 21px;
$font-lm: 25px;
$font-xl: 30px;
$font-xlm: 35px;
$font-xxl: 53px;
