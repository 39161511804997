@import '../scss/base.scss';

.tag {
	background: #eee;
	border-radius: 3px 0 0 3px;
	color: #999;
	display: inline-block;
	height: 20px;
	line-height: 20px;
	padding: 0 20px 0 23px;
	position: relative;
	margin: $unit $gap-side $unit 0;
	text-decoration: none;
	transition: color 0.2s;
	font-size: $font-s;
	cursor: pointer;

	&::before {
		background: #fff;
		border-radius: 10px;
		box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
		content: '';
		height: 6px;
		left: 10px;
		position: absolute;
		width: 6px;
		top: 7px;
	}

	&::after {
		background: #fff;
		border-bottom: 10px solid transparent;
		border-left: 10px solid #eee;
		border-top: 10px solid transparent;
		content: '';
		position: absolute;
		right: 0;
		top: 0;
	}

	&:hover {
		background-color: $link-color;
		color: white;

		&::after {
			border-left-color: $link-color;
		}
	}
}
