@import '../scss//base.scss';

.heading {
	font-size: $font-lm;
	padding: $gap-side;
	margin-bottom: $gap-middlePlus;
	border-bottom: 2px solid $faded-dark-grey;
	height: 40px;
	position: relative;
	display: flex;
	justify-content: flex-start;

	@include text-bold;

	&__title {
		display: flex;
		align-items: center;
		left: 0;
		&__icon {
			margin-right: $gap-top;
		}
	}

	&__tabs {
		display: flex;
		align-items: center;
		margin-left: 12px;

		> div {
			background-color: rgba(0, 114, 199, 0.5);
			color: white;
			font-size: $font-m;
			border-radius: 2px;
			padding: 4px 12px;
			cursor: pointer;
		}

		.selected {
			background-color: rgba(0, 114, 199, 1);
		}

		> div:first-of-type {
			margin-right: 24px;
		}
	}

	&--sub {
		font-size: $font-ml;
	}
}
