@import '../../../common/scss/base.scss';

.publication-search {
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	margin: $gap-singlePlus $none $gap-single;

	@include mq($from: tablet) {
		flex-direction: row;
		align-items: flex-start;
	}

	&__filters {
		max-width: 100%;
		&__more-toggle {
			display: inline-flex;
			align-items: center;
			font-size: $font-s;
			color: $montrose-blue;
			cursor: pointer;
			outline: none;
			-webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
			margin-top: $gap-side;
	
			@include text-bold;
	
			> span {
				margin-right: $gap-side;
			}
	
			> div {
				display: flex;
			}
		}
	}


	&__btn {
		display: flex;
		flex-direction: column;
		align-items: center;

		&__submit {
			margin: $gap-top $none;

			@include mq($from: tablet) {
				margin: $gap-top $gap-top $gap-side;
			}
		}

		&__clear {
			font-weight: bold;
			color: $montrose-blue;
			cursor: pointer;
		}

	}

	.autocomplete {
		max-width: 100%;
	}
}