@import '../../common/scss/base.scss';

$doublePadding: $gap-single * 2;

.homepage {
	background-color: $white;

	&__image-container {
		width: 100%;
		position: relative;

		.image-gallery-image {
			margin-left: 0;
		}

		img {
			width: calc(100% + 48px);
			margin-left: -$gap-single;
			margin-top: -$gap-single;
			filter: brightness(80%);
		}

		&__text {
			position: relative;
			color: $dark-grey;
			font-weight: 600;
			padding: $gap-top;
			margin-bottom: $gap-single;
			text-align: center;
			max-width: 910px;
			margin: auto;

			> span {
				display: block;
				margin: $gap-middlePlus $none;
			}
		}
	}

	&__information {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		flex-flow: wrap;

		@include mq($from: desktop) {
			flex-direction: row;
		}

		&__success-stories {
			max-width: 720px;
		}
	}

	&__partners {
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: $gap-double $none;
		flex-wrap: wrap;
		> img {
			height: 60px;
			object-fit: contain;
			margin: $gap-single $gap-side;
		}

		#unfpa-logo {
			height: 85px;
		}

		#unite-logo {
			height: 130px;
		}
	}
}
