@import '../../../common/scss/base.scss';

.publish-title {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: $gap-middlePlus;
	margin-bottom: $gap-singlePlus;

	@include mq($from: 550px) {
		grid-template-columns: 1fr;
		grid-row-gap: $none;
		grid-column-gap: $gap-single;
	}

	&__container {
		display: flex;
		flex-direction: column;
		font-weight: bold;
		color: $dark-grey;
		font-size: $font-s;

		> input {
			height: 40px;
			margin: $unit $none;
			padding: $none $gap-side;
			border: 1px solid $light-grey;
			border-radius: $border-radius;
			font-size: $font-m;
		}
	}
}
