@import '../../../common/scss/base.scss';

.doc-type-toggle {
	display: flex;
	justify-content: center;
	margin-bottom: $gap-singlePlus;

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__box {
		display: flex;
		justify-content: center;
		width: 150px;
		border: 2px dashed $light-grey;
		padding: $unit $gap-top;
		cursor: pointer;

		&--left {
			border-bottom-left-radius: $border-radius;
			border-top-left-radius: $border-radius;
			border-right-width: 1px;
		}

		&--right {
			border-bottom-right-radius: $border-radius;
			border-top-right-radius: $border-radius;
			border-left-width: 1px;
		}

		&--selected {
			background-color: $montrose-blue;
			border-color: $montrose-blue;
			border-style: solid;
			color: $white;
			font-weight: bold;
			cursor: default;
		}
	}
}
