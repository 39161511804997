@import '../scss/base.scss';

#iubenda {
	z-index: 9000;
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	background-color: rgba(0, 0, 0, 0.7);
	&__container {
		background-color: $white;
	}
}

.cookie {
	padding: 40px;
	
	@include mq($from: 850px) {
		max-width: 900px;
		margin: auto;
	}

	p {
		margin: 0;

		a {
			text-decoration: underline;
			color: $dark-grey;
			font-weight: bold;
			
		}
	}

	&__btn-container {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include mq($from: 470px) {
			flex-direction: row;
		}

		> div {
			margin: $gap-side 0;
			width: 100%;
			@include mq($from: 470px) {
				width: auto;
			}

			> button {
				width: 100%;
				@include mq($from: 470px) {
					width: auto;
				}
			}
		}


		&__adjust > button {
			color: #f44336;
			border-color: $faded-dark-grey;
			margin-right: $gap-side;
			&:hover {
				background-color: #f44336;
				color: $white;
				border-color: #f44336;
			}
		}
	}

	&__settings-container {
		display: flex;
		flex-direction: column;
		margin: $gap-side $none;

		&__checkbox {
			margin: $none $unit;
		}
	}
}