@import '../../../common/scss/base.scss';

.publish-options {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: $gap-middlePlus;
	margin-bottom: $gap-singlePlus;

	@include mq($from: 550px) {
		grid-template-columns: 1fr 1fr;
		grid-row-gap: $none;
		grid-column-gap: $gap-single;
	}

	&__section {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;

		&__title {
			font-weight: bold;
			color: $dark-grey;
			font-size: $font-s;
		}

		&__new {
			display: flex;
			justify-content: space-between;

			&__submit {
				margin: $unit $gap-side $unit $none;
			}

			> input {
				margin: $unit $none;
				padding: $none $gap-side;
				width: 100%;
				border: 1px solid $light-grey;
				border-radius: $border-radius;
			}
		}
	}

	&__organisations__list {
		margin: $none;
		height: 200px;
		border: 1px solid $light-grey;
		border-radius: $border-radius;
		overflow: scroll;
		padding: $unit $unit;

		&__item {
			height: 40px;
			border-bottom: 1px solid $light-grey;
			line-height: 40px;
			cursor: pointer;
			padding: $none $unit;

			&--selected {
				background-color: $montrose-blue;
				color: $white;
				font-weight: bold;
			}
		}

		&--loading {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__tags__list {
		height: 200px;
		border: 1px solid $light-grey;
		border-radius: $border-radius;
		padding: $unit $unit;
	}
}
