@import './common/scss/base.scss';

body {
	margin: 0;
	font-family: $font-family;
	line-height: 1.7em;
	min-height: 100vh;
	color: $dark-grey;
	font-size: $global-font-base;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	max-width: 1280px;
	background-color: $montrose-grey;
	margin: 0 auto;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
