@import '../scss/base.scss';

.common-image {
	width: 100%;
	position: relative;
	overflow: hidden;
	object-fit: contain;

	@include mq($from: mobile) {
		height: 300px;
	}

	@include mq($from: tablet) {
		height: 400px;
	}
}

.common-image-caption {
	display: block;
	text-align: center;
	font-style: italic;
	font-size: $font-s;
}