@import '../../common/scss/base.scss';

.key-item {
	display: flex;
	margin: $gap-top $none;
	align-items: center;

	@include mq($from: 390px) {
		width: 330px;
	}

	&__box {
		width: 45px;
		height: 35px;
		border-radius: $border-radius;
		margin-right: $gap-side;
		display: flex;
		justify-content: center;
		align-items: center;

		&--orange {
			background-color: rgb(237, 126, 49);
		}

		&--blue {
			background-color: rgb(32, 65, 132);
		}

		&--purple {
			background-color: rgb(88, 48, 162);
		}

		&--yellow {
			background-color: rgb(236, 204, 70);
		}

		.diamond {
			align-self: flex-start;

			&:after {
				z-index: unset;
			}
		}
	}

	&__text {
		font-size: $font-s;
		width: 270px;
		line-height: 1.3em;
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		> span {
			display: block;
		}

		&--icon {
			width: unset;
		}
	}

	&--icon {
		width: 300px;

		@include mq($from: 950px, $until: 1050px) {
			width: 250px;
		}

		@include mq($from: tablet) {
			margin: $gap-top;
		}
	}
}