@import '../../scss/base.scss';

.review {
	position: relative;
	margin: $gap-top;
	border-bottom: 1px solid $light-grey;

	&__actions {
		&__cta-container {
			display: flex;
			flex-direction: row;
			margin: $gap-side $gap-top;

			&__share {
				display: flex;
				margin-right: $gap;

				.fa > svg {
					opacity: 0.75;
					color: $link-color;
				}

				&:hover {
					cursor: pointer;

					.fa > svg {
						transition: ease-in-out 0.4s;
						opacity: 1;
					}
				}
			}

			&__download {
				display: block;
				color: $link-color;
				text-decoration: none;
				font-size: $font-s;
				opacity: 0.75;
				transition: ease-in-out 0.4s;
				cursor: pointer;
				&:hover {
					transition: ease-in-out 0.4s;
					opacity: 1;
				}
			}
		}
	}

	&__content {
		padding: $gap-top $gap-top 0 $gap-top;
		position: relative;
		border-radius: $border-radius;
		overflow: hidden;
		text-decoration: none;
		width: 100%;
		line-height: 1.4em;

		&__info {
			display: flex;
			flex-direction: column;
			margin-bottom: $gap-side;

			&__title {
				color: $montrose-dark-blue;
				margin-bottom: $unit;

				@include text-bold;
			}

			&__key {
				font-size: $font-s;
				color: $semi-faded-dark-grey;
			}

			&__value {
				color: $dark-grey;
			}
		}

		&__description {
			font-size: $font-s;
			line-height: 1.3em;
			color: $semi-faded-dark-grey;
			margin-bottom: $gap-side;
		}
	}
}
