@import '../../scss/base.scss';

.documents {
	width: 100%;
	min-height: 100px;
	margin-bottom: $gap + $gap-top;

	&__list {
		display: flex;
		flex-direction: column;
	}

	&__pagination {
		display: flex;
		justify-content: center;
	}
}
