@import '../../common/scss/base.scss';

.about-text {

	&__section {
		display: block;
		margin: 1em $none;
	}

	&__list {
		margin: $none;
	}
}