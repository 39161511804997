@import '../scss/base.scss';

.editor {
	margin-bottom: $gap-singlePlus;

	&__wrapper {
		border: 1px solid $faded-dark-grey;
		border-radius: $border-radius;
	}

	&__toolbar {
		border-width: 0 0 1px 0;
		border-bottom-color: $light-grey;
		border-radius: $border-radius;
		font-size: $font-s;
		position: sticky;
		top: 0;
		z-index: 10;

		> div {
			> div {
				border-radius: $border-radius;
				border-color: $light-grey;
				&:hover {
					box-shadow: none;
					border-color: $dark-grey;
				}
			}
			.rdw-option-active {
				box-shadow: none;
				border-color: $dark-grey;
			}
		}
	}

	&__input-field {
		min-height: 150px;
		padding: $gap-side;
	}
}

.image-caption {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	font-style: italic;
	> div {
		text-align: center;
		margin: 0;
	}
}

.inline-image {
	margin: 0;
}
