@import '../../common/scss/base.scss';

.login {
  background-color: white;
  border: 1px solid black;
  max-width: 500px;
  margin: 32px auto;
  display: flex;
	flex-direction: column;
	padding: $gap-single;
	border-radius: $border-radius;
	
	&__title {
		font-weight: bold;
		font-size: $font-m;
	}

	&__email {
		margin: $gap-single $none;
	}

	> label {
		margin-top: $gap-middlePlus;
	}

	&__button {
		margin-top: $gap-middlePlus;
		align-self: flex-end;
	}
}