@import '../scss/base.scss';

.footer {
	flex-shrink: 0;
	display: flex;
	min-height: 100px;
  background-color: $montrose-blue;
	color: $white;
	padding: $none $gap-single;
	font-size: $font-s;

	&__links {
		flex: 1;
		display: flex;
		flex-flow: wrap;
		justify-content: center;
		align-items: flex-start;
		margin: $gap-middlePlus $none;
		
		@include mq($from: mobile) {
			align-items: center;
			justify-content: flex-start;
		}

		&__divider {
			display: none;

			@include mq($from: mobile) {
				display: contents;
			}
		}

		> a {
			text-decoration: none;
			color: $white;
			margin: $unit $gap-side; 
			text-align: center;

			@include mq($from: mobile) {
				margin: $none $gap-side;
				text-align: center;
			}
		}
	}

	&__social-media {
		display: flex;
		align-items: center;

		&__icon {
			margin: $none $gap-side;
			cursor: pointer;
		}
	}
}