@import '../../../common/scss/base.scss';

.create-modal {
	display: flex;
	flex-direction: column;
	padding: $gap-single;

	&__btn {
		align-self: flex-end;
	}

	&__email-input {
		width: 100%;
		margin-bottom: $gap-side;
	}

	&__reset {
		font-weight: bold;
		text-align: center;
		color: $montrose-blue;
		margin: 10px 0;
		cursor: pointer;
	}

	&__role {
		width: 100%;
		height: 40px;
		padding: $gap-side;
		font-size: $font-s;
		font-family: $font-family;
		border: 1px solid $light-grey;
		border-radius: $border-radius;
		margin-bottom: $gap-side;

		&__label {
			font-weight: bold;
			color: $dark-grey;
			font-size: $font-s;
		}
	}
}
