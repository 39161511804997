$none: 0;
$unit: 4px;
$gap-side: 8px;
$gap-top: 12px;
$gap-bottom: 32px;
$gap: 16px + $gap-side;
$box-radius: $unit;
$box-radius--wide: $unit * 2;
$runner-image: 32px;

$gutter: 15px;

$gap-single: $unit * 6; //24
$gap-middle: calc($gap-single / 2); //12;

$gap-singlePlus: $gap-single + $gap-middle; //36
$gap-middlePlus: $gap-middle + $unit; //16

$gap-double: $gap-single * 2; //48
$gap-doublePlus: $gap-double + $gap-middle; //60

$gap-quadruple: $gap-double * 2; //96
$gap-quadruplePlus: $gap-quadruple + $gap-middle; //108

$vm-mobile: 45vw;
$vm-tablet: 32vw;
$vm-default: 36vw;

$filter-width-default: $gap-single * 17;
$filter-width-tablet: $gap-single * 14;

$home-card--border-width: 8px;
$home-card--border-width-active: 8px;

$menu-width-default: 130px;

$header-mobile: $gap-doublePlus + $gap-middle;

$max-width: 1024px;
$input-height: 56px;
$header-height: 72px;
$body-padding: 32px;