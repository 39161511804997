.edit-button {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1;
	cursor: pointer;
	
	&:hover {
		opacity: 0.5;
	}
}