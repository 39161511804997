@import '../../common/scss/base.scss';

.activities {
	display: flex;
	flex-direction: column;
	margin-bottom: $gap-single;

	&__note {
		font-style: italic;
		font-weight: bold;
		font-size: $font-s;
	}
}

.fact-sheet {
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	align-items: center;

	&__info {
		text-align: center;
	}

	&__dropdown > div {
		margin: $gap-side $none;	
		width: 250px;
	}

	&__btn {
		margin: $none $gap;
	}
} 