@import '../../common/scss/base.scss';

.calendar {
	text-align: center;
	> span {
		display: inline-block;
		margin: $gap-middlePlus $none;
	}

	> div {
		margin: $gap-middlePlus $none;
	}

	.rbc-toolbar {
		justify-content: space-around;

		> span {
			margin: $unit $none;
		}

		.rbc-toolbar-label {
			flex-grow: 0;
			padding: $none $gap-single;
		}
	}

}