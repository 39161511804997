@import '../../scss/base.scss';

.district__marker {
	color: white;
	font-size: $font-m;
	outline: none;

	@include text-bold;

	&--western {
		min-width: 90px;
		height: auto !important;
	}

	&--hide {
		display: none;
	}
}

.chiefdom__marker {
	color: white;
	font-size: $font-s;
	outline: none;
	width: 50px;
}

.chiefdom-label {
	display: flex;
	flex-direction: column;

	&__icon {
		width: 20px;
		height: 20px;
	}
}