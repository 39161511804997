.fa {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	outline: none;

	> span {
		top: 6px;
	}

	&__baby > span {
			top: -2px;
	}

	&__home > span {
			top: 10px;
	}

	&__faucet > span {
		top: 8px;
		left: 2px;
	}

	&__number {
		position: absolute;
		color: black;
		z-index: 1000;
		font-weight: 600;
		font-size: 10px;

		&--selected {
			color: white;
		}
	}
}

.font-white > span {
	color: #fff;
}