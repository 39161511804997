@import '../scss/base.scss';

.square {
	height: 20px;
	width: 20px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	@include text-bold;

	&__selected {
		color: white;
		background-color: red;
	}
}

.triangle {
	width: 0;
	height: 0;
	border-right: 15px solid transparent;
	border-left: 15px solid transparent;
	border-bottom: 23px solid white;
	position: relative;
	> span {
		position: absolute;
    left: -4px;
    display: block;
    right: 0;
		top: 5px;
		@include text-bold;
	}

	&__selected {
		color: white;
		border-bottom-color: red;
	}
}

.diamond {
	border-style: solid;
	border-color: transparent transparent white transparent;
	border-width: 0 10px 10px 10px;
	height: 0;
	width: 10px;
	box-sizing: content-box;
	position: relative;	
	&:after {
		content: "";
		position: absolute;
		top: 10px;
		left: -10px;
		width: 0;
		height: 0;
		border-style: solid;
		border-color: white transparent transparent transparent;
		border-width: 20px 15px 0 15px;
		z-index: -1;
	}
	> span {
		position: absolute;
    left: 1px;
    right: 0;
		top: 2px;
		@include text-bold;
	}

	&__selected  {
		border-color: transparent transparent red transparent;
		color: white;
		&:after {
			border-color: red transparent transparent transparent;
		}
	}
}

.star {
	position: relative;
	display: block;
	width: 0px;
	height: 0px;
	border-right: 17px solid transparent;
	border-bottom: 14px solid white;
	border-left: 17px solid transparent;
	transform: rotate(38deg);

	&:before {
		border-bottom: 14px solid white;
		border-left: 7px solid transparent;
		border-right: 7px solid transparent;
		position: absolute;
		height: 0;
		width: 0;
		top: -7px;
		left: -13px;
		display: block;
		content: '';
		transform: rotate(-37deg);
	}

	&:after {
		position: absolute;
		display: block;
		top: 1px;
		left: -18px;
		width: 0px;
		height: 0px;
		border-right: 17px solid transparent;
		border-bottom: 14px solid white;
		border-left: 17px solid transparent;
		transform: rotate(-78deg);
		content: '';
		z-index: -1;
	}

	> span {
		position: absolute;
    transform: rotate(-38deg);
    top: -1px;
    left: -4px;
    @include text-bold;
	}

	&__selected {
		border-bottom-color: red;
		color: white;

		&:before {
			border-bottom-color: red;
			color: white;
		}

		&:after {
			border-bottom-color: red;
			color: white;
		}
	}
}

.circle {
	width: 25px;
	height: 25px;
	background: white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	@include text-bold;

	&__selected {
		color: white;
		background-color: red;
	}
}

.octagon {
	width:25px;
	height:25px;
	float: left;
	position: relative;
	overflow: hidden;
	> div {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
    top: 0; right: 0; bottom: 0; left: 0;
    overflow: hidden;
    transform: rotate(45deg);
    background: white;
		
		&:before {
			position: absolute;
			transform: rotate(45deg);
			content: '';
			border: inherit;
		}

		> span {
			transform: rotate(-45deg);
			@include text-bold;
		}
	}

	&__selected > div {
		background: red;
		color: white;
	}
}