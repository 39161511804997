@import '../../../common//scss/base.scss';

.table {
	width: 100%;
	border: 1px solid $light-grey;
	border-radius: $border-radius;
	border-collapse: collapse;
	table-layout: fixed;
	&__body {
		width: 100%;
	
		> tr:nth-child(odd) {
			background-color: $montrose-grey;
		}
	
		&__header {
			> th {
				background-color: $montrose-light-blue;
				color: $white;
				padding: $gap-side $none $gap-side $gap-side;
				text-align: left;
			}
		}
	
		> tr td {
			padding: $gap-side $none $gap-side $gap-side;
			word-wrap: break-word;
		}
	}
} 
