@import '../../../common/scss/base.scss';

.gallery-admin {
	&--loading {
		margin-top: 20%;
		display: flex;
		justify-content: center;
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, 275px);
		grid-row-gap: 20px;
		justify-content: center;
	}

	&__item {
		width: 250px;
		position: relative;
		display: inline-block;
		margin: $gap-middlePlus;
		max-width: 100%;
		-webkit-column-break-inside: avoid;
		cursor: move;
		cursor: grab;
		cursor: -moz-grab;
		cursor: -webkit-grab;

		.inner {
			padding: $gap-middlePlus;
		}

		&:active {
			cursor: grabbing;
			cursor: -moz-grabbing;
			cursor: -webkit-grabbing;
		}
	}

	&__cta-container {
		> div {
			display: inline-block;
			margin-right: $gap-side;
		}
	}

	&__modal__description {
		margin-bottom: $gap-side;
	}
}

.content-box {
	border: 0px solid $montrose-grey;
	background-color: $white;
	box-shadow: 0px -1px 2px $faded-dark-grey, 1px 1px 2px $faded-dark-grey;
	line-height: 1.5em;
	font-size: $font-s;

	&:before {
		content: '';
		position: absolute;
		display: block;
		width: 100%;
		height: 4rem;
		left: 0px;
		top: 0px;
	}

	&__thumb {
		display: block;
		width: 100%;
		height: 200px;
		object-fit: cover;
		margin: $unit 0;
	}

	&__description {
		display: block;
		display: -webkit-box;
		margin: 0rem;
		max-width: 100%;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__cta-container {
		padding: $gap-side $none;
		display: flex;
		justify-content: space-around;

		> div {
			cursor: pointer;
		}
	}
}

.gallery-admin-modal {
	&__description {
		margin-bottom: $gap-side;
	}

	&__submit {
		display: flex;
		justify-content: center;
	}
}
