@import '../../common/scss/base.scss';

.gallery {
	@include page-contain;

	&__caption {
		text-align: center;
		height: 50px;
	}

	.image-gallery-slide-wrapper {
		background-color: #000;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 50px;

		@include mq($from: mobile) {
			margin: 0;
			min-height: 400px;
			img {
				max-height: 400px !important;
			}
		}

		@include mq($from: desktop) {
			min-height: 450px;
			img {
				max-height: 450px !important;
			}
		}

		.image-gallery-left-nav,
		.image-gallery-right-nav {
			> svg {
				height: 75px;
			}
		}
	}

	.gallery-loader {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 200px;
	}

	.playlist-item {
		padding: 8px;
		border-radius: 2px;
		margin-right: 8;
		cursor: pointer;

		&:hover {
			background-color: rgba(0, 114, 199, 0.2);
		}

		> p {
			margin: 0;
			overflow: hidden;
			text-overflow: ellipsis;
			font-size: 12px;
			white-space: nowrap;
			line-height: 16px;

			&:first-of-type {
				font-weight: bold;
			}
		}
	}
}
