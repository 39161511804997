@import '../../../common/scss/base.scss';

.add-event {
	padding: $gap-single;
	&__step-one {
		margin-bottom: $gap-middlePlus;
		> div {
			margin-bottom: $gap-middlePlus !important;
		}
	}

	&__step-two {
		display: flex;
		flex-direction: column;
		margin-bottom: $gap-middlePlus;
		&__date {
			display: flex;
			flex-wrap: wrap;

			width: 100%;
			margin: $gap-middlePlus $none;

			&__time {
				margin-left: $gap;
			}
		}
	}

	&__step-three {
		margin-bottom: $gap-middlePlus;
	}

	&__step-four {
		margin-bottom: $gap-middlePlus;
		&__emails {
			border: 1px solid $light-grey;
			border-radius: $border-radius;
			padding: $unit;
			margin-bottom: $gap-middlePlus;
			& > * {
				margin: $unit;
			}
			> div {
				color: $montrose-blue;
				border-color: $montrose-blue;

				> .MuiChip-deleteIcon {
					color: $faded-montrose-blue;
					&:hover {
						color: $montrose-blue;
					}
				}
			}
		}

		&__notifications {
			display: flex;
			margin-bottom: $gap-middle;

			&__quantity > input {
				width: 70px;
				margin-right: $gap-side;
			}

			&__units {
				border: 1px solid $light-grey;
				border-radius: $border-radius;
				font-size: $font-m;
				padding: $none $gap-side;
				width: 120px;
			}

			&__message {
				font-size: $font-s;
			}

			> :first-of-type div {
				margin-right: $gap-side;
			}
		}
	}

	&__cta {
		display: flex;
		justify-content: space-between;

		&__submit {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
		}

		&__validation-error {
			color: red;
			font-size: $font-s;
		}
	}
}
