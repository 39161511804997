@import '../../../common/scss/base.scss';

.publish-upload {
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: $gap-middlePlus;
	margin-bottom: $gap-singlePlus;

	@include mq($from: 550px) {
		grid-template-columns: 1fr 1fr;
		grid-row-gap: $none;
		grid-column-gap: $gap-middlePlus;
	}
}
