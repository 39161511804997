@import './common/scss/base.scss';

.app {
	background-color: $white;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;

	&__pages {
		flex: 1 0 auto;
		padding: $gap-single;
		position: relative;
	}
}

#staging-banner {
	background: coral;
	color: white;
	font-weight: bold;
	font-size: 12px;
	text-align: center;
	position: fixed;
	z-index: 10000;
	opacity: 0.9;
	width: 100vw;
	left: 0;
}