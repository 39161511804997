@import '../../common/scss/base.scss';

.partners {
	@include page-contain;

	&__intro {
		text-align: center;
		font-size: $font-ml;
	}

	&__section-intro {
		> p {
			text-align: justify;
		}
	}

	&__logos {
		display: flex;
		justify-content: space-around;
		margin: 1em $none;
		flex-wrap: wrap;
		> img {
			height: 60px;
			object-fit: contain;
			margin: $unit $gap-side;
		}
	}

	&__activities {
		margin: 2em $none;
		min-height: 50px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
	}

	&__montrose {
		margin-bottom: $gap-singlePlus;
		display: flex;
		flex-direction: column;
		align-items: center;

		&__title {
			display: flex;
			width: 400px;
			max-width: 100%;
			text-align: center;
	
			@include text-bold;
		}

		&__icons {
			display: flex;
			flex-flow: wrap;
			justify-content: center;

			> div > img {
					width: 75px;
					margin: $gap-side;
				}
		}

		&__logo {
			max-width: 100%;
			width: 300px;
		}
	}
}

.infobox {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 275px;
	font-size: $font-s;
	text-align: center;
	margin: $gap-single;
}

.line-break {
	border: 1.5px solid $light-grey;
	margin: $gap-single $none;
}